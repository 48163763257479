import React from 'react';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import moment from 'moment';
import { fetchSupportForms, showDeleteFormDialog, updateSupportForms } from '../../actions/home';
import { Button } from '@material-ui/core';

const SupportFormsTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.onChange(props.skip, props.limit, props.sortBy, props.order);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.onChange(props.limit * currentPage, props.limit, props.sortBy, props.order);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.onChange(props.skip, numberOfRows, props.sortBy, props.order);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.onChange(props.skip, props.limit, changedColumn, direction);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
        title: true,
    };

    const handleUpdate = (value) => {
        const data = {
            id: value._id,
        };
        props.updateSupportForms(value._id, data, (cb) => {
            if (cb) {
                props.onChange(props.skip, props.limit, props.sortBy, props.order);
            }
        });
    };

    const columns = [{
        name: 'name',
        label: 'Name',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div>{value}</div>
                );
            },
        },
    }, {
        name: 'email_address',
        label: 'Email',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>{value}</div>
                );
            },
        },
    }, {
        name: 'purpose',
        label: 'Purpose',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">{value} </div>
                );
            },
        },
    }, {
        name: 'description',
        label: 'Description',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">{value} </div>
                );
            },
        },
    }, {
        name: 'created_at',
        label: 'Created At',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value && moment(value).format('MMM Do, h:mm A')}
                    </div>
                );
            },
        },
    }, {
        name: 'status',
        label: 'Status',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="status_button">
                        {value.status === 'NOT_RESOLVED'
                            ? props.updateInProgress && ((props.updateForm && props.updateForm._id) === value._id)
                                ? <Button>
                                    <CircularProgress />
                                    Resolve
                                </Button>
                                : <Button onClick={() => handleUpdate(value)}>
                                    Resolve
                                </Button>
                            : <span>{value.status}</span>}
                        <Button
                            className="delete_button"
                            onClick={() => props.showDeleteFormDialog(value)}>
                            Delete
                        </Button>
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.name,
                item.email_address,
                item.purpose,
                item.description,
                item.created_at,
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

SupportFormsTable.propTypes = {
    deleteId: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    showDeleteFormDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    updateForm: PropTypes.object.isRequired,
    updateInProgress: PropTypes.bool.isRequired,
    updateSupportForms: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    order: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.supportForms.list,
        inProgress: state.home.supportForms.inProgress,
        skip: state.home.supportForms.skip,
        limit: state.home.supportForms.limit,
        sortBy: state.home.supportForms.sortBy,
        order: state.home.supportForms.order,
        total: state.home.supportForms.count,
        updateInProgress: state.home.updateSupportForms.inProgress,
        updateForm: state.home.updateSupportForms.value,
        deleteInProgress: state.home.deleteSupportForm.inProgress,
        deleteId: state.home.deleteSupportForm.value,
    };
};

const actionsToProps = {
    onChange: fetchSupportForms,
    updateSupportForms,
    showDeleteFormDialog,
};

export default connect(stateToProps, actionsToProps)(SupportFormsTable);
