import * as React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../Home/DeleteEventDialog/index.css';
import CircularProgress from '../../components/CircularProgress';
import { deleteGatedAccess, fetchGatedAccess, hideDeleteGatedAccessDialog } from '../../actions/gatedAccess';

const DeleteDialog = (props) => {
    const handleDelete = () => {
        const eventID = props.value && props.value.event && props.value.event._id;

        props.deleteGatedAccess(eventID, props.value && props.value._id, (result) => {
            if (result) {
                props.fetchGatedAccess(eventID);
                props.hideDialog();
            }
        });
    };

    const disable = props.inProgress;

    return (
        <Dialog
            aria-describedby="add-event-dialog-description"
            aria-labelledby="add-event-dialog-title"
            className="dialog add_event_dialog delete_dialog"
            open={props.open}
            onClose={props.hideDialog}>
            <DialogTitle id="alert-dialog-title">
                Delete Gated Access confirmation
            </DialogTitle>
            {props.inProgress ? <CircularProgress className="full_screen"/> : null}
            <DialogActions className="actions">
                <Button onClick={props.hideDialog}>Cancel</Button>
                <Button autoFocus className="add_button" disabled={disable} onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteDialog.propTypes = {
    deleteGatedAccess: PropTypes.func.isRequired,
    fetchGatedAccess: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.gatedAccess.deleteDialog.open,
        value: state.gatedAccess.deleteDialog.value,
        inProgress: state.gatedAccess.addGatedAccess.inProgress,
    };
};

const actionToProps = {
    deleteGatedAccess,
    fetchGatedAccess,
    hideDialog: hideDeleteGatedAccessDialog,
};

export default connect(stateToProps, actionToProps)(DeleteDialog);
