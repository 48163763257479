import {
    DELETE_SUPPORT_FORM_URL,
    ORDERS_URL,
    STATS_URL,
    SUPPORT_FORMS_URL,
    TICKET_TYPES_URL,
    TICKETS_INFO_URL,
    UPDATE_SUPPORT_FORMS_URL, UPDATE_TICKET_TYPE_URL,
} from '../constants/url';
import Axios from 'axios';
import {
    DELETE_FORM_DIALOG_HIDE,
    DELETE_FORM_DIALOG_SHOW,
    ORDERS_FETCH_ERROR,
    ORDERS_FETCH_IN_PROGRESS,
    ORDERS_FETCH_SUCCESS,
    STATS_FETCH_ERROR,
    STATS_FETCH_IN_PROGRESS,
    STATS_FETCH_SUCCESS,
    SUPPORT_FORM_DELETE_ERROR,
    SUPPORT_FORM_DELETE_IN_PROGRESS,
    SUPPORT_FORM_DELETE_SUCCESS,
    SUPPORT_FORMS_FETCH_ERROR,
    SUPPORT_FORMS_FETCH_IN_PROGRESS,
    SUPPORT_FORMS_FETCH_SUCCESS,
    SUPPORT_FORMS_UPDATE_ERROR,
    SUPPORT_FORMS_UPDATE_IN_PROGRESS,
    SUPPORT_FORMS_UPDATE_SUCCESS,
    TICKET_PRICE_SET,
    TICKET_TYPE_FETCH_ERROR,
    TICKET_TYPE_FETCH_IN_PROGRESS,
    TICKET_TYPE_FETCH_SUCCESS,
    TICKETS_INFO_FETCH_ERROR,
    TICKETS_INFO_FETCH_IN_PROGRESS,
    TICKETS_INFO_FETCH_SUCCESS,
    UPDATE_TICKET_TYPE_DIALOG_HIDE,
    UPDATE_TICKET_TYPE_DIALOG_SHOW,
    UPDATE_TICKET_TYPE_ERROR, UPDATE_TICKET_TYPE_IN_PROGRESS, UPDATE_TICKET_TYPE_SUCCESS,
} from '../constants/home';

export const showDeleteFormDialog = (value) => {
    return {
        type: DELETE_FORM_DIALOG_SHOW,
        value,
    };
};

export const setTicketPrice = (value) => {
    return {
        type: TICKET_PRICE_SET,
        value,
    };
};

export const hideDeleteFormDialog = () => {
    return {
        type: DELETE_FORM_DIALOG_HIDE,
    };
};

export const showUpdateTicketTypeDialog = (value) => {
    return {
        type: UPDATE_TICKET_TYPE_DIALOG_SHOW,
        value,
    };
};

export const hideUpdateTicketTypeDialog = () => {
    return {
        type: UPDATE_TICKET_TYPE_DIALOG_HIDE,
    };
};

const fetchTicketsInfoInProgress = () => {
    return {
        type: TICKETS_INFO_FETCH_IN_PROGRESS,
    };
};

const fetchTicketsInfoSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: TICKETS_INFO_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchTicketsInfoError = (message) => {
    return {
        type: TICKETS_INFO_FETCH_ERROR,
        message,
    };
};

export const fetchTicketsInfo = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchTicketsInfoInProgress());

    const url = TICKETS_INFO_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_AWDashboard'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchTicketsInfoSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchTicketsInfoError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchTicketTypeInProgress = () => {
    return {
        type: TICKET_TYPE_FETCH_IN_PROGRESS,
    };
};

const fetchTicketTypeSuccess = (items, skip, limit, sortBy, order) => {
    return {
        type: TICKET_TYPE_FETCH_SUCCESS,
        items: items,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchTicketTypeError = (message) => {
    return {
        type: TICKET_TYPE_FETCH_ERROR,
        message,
    };
};

export const fetchTicketType = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchTicketTypeInProgress());

    const url = TICKET_TYPES_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_AWDashboard'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result;
            dispatch(fetchTicketTypeSuccess(items, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchTicketTypeError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateTicketTypeInProgress = () => {
    return {
        type: UPDATE_TICKET_TYPE_IN_PROGRESS,
    };
};

const updateTicketTypeSuccess = (value, message) => {
    return {
        type: UPDATE_TICKET_TYPE_SUCCESS,
        value,
        message,
    };
};

const updateTicketTypeError = (message) => {
    return {
        type: UPDATE_TICKET_TYPE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateTicketType = (id, data, cb) => (dispatch) => {
    dispatch(updateTicketTypeInProgress());

    const url = UPDATE_TICKET_TYPE_URL(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_AWDashboard'),
        },
    })
        .then((res) => {
            dispatch(updateTicketTypeSuccess(res.data && res.data.result, 'Success'));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(updateTicketTypeError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchOrdersInProgress = () => {
    return {
        type: ORDERS_FETCH_IN_PROGRESS,
    };
};

const fetchOrdersSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: ORDERS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchOrdersError = (message) => {
    return {
        type: ORDERS_FETCH_ERROR,
        message,
    };
};

export const fetchOrders = (skip, limit, sortBy, order, searchKey, status) => (dispatch) => {
    dispatch(fetchOrdersInProgress());

    const url = ORDERS_URL(skip, limit, sortBy, order, searchKey, status);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_AWDashboard'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchOrdersSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchOrdersError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchStatsInProgress = () => {
    return {
        type: STATS_FETCH_IN_PROGRESS,
    };
};

const fetchStatsSuccess = (value) => {
    return {
        type: STATS_FETCH_SUCCESS,
        value,
    };
};

const fetchStatsError = (message) => {
    return {
        type: STATS_FETCH_ERROR,
        message,
    };
};

export const fetchStats = () => (dispatch) => {
    dispatch(fetchStatsInProgress());

    Axios.get(STATS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_AWDashboard'),
        },
    })
        .then((res) => {
            dispatch(fetchStatsSuccess(res && res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSupportFormsInProgress = () => {
    return {
        type: SUPPORT_FORMS_FETCH_IN_PROGRESS,
    };
};

const fetchSupportFormsSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: SUPPORT_FORMS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchSupportFormsError = (message) => {
    return {
        type: SUPPORT_FORMS_FETCH_ERROR,
        message,
    };
};

export const fetchSupportForms = (skip, limit, sortBy, order) => (dispatch) => {
    dispatch(fetchSupportFormsInProgress());

    const url = SUPPORT_FORMS_URL(skip, limit, sortBy, order);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_AWDashboard'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchSupportFormsSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchSupportFormsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateSupportFormsInProgress = () => {
    return {
        type: SUPPORT_FORMS_UPDATE_IN_PROGRESS,
    };
};

const updateSupportFormsSuccess = (value, message) => {
    return {
        type: SUPPORT_FORMS_UPDATE_SUCCESS,
        value,
        message,
    };
};

const updateSupportFormsError = (message) => {
    return {
        type: SUPPORT_FORMS_UPDATE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateSupportForms = (id, data, cb) => (dispatch) => {
    dispatch(updateSupportFormsInProgress());

    const url = UPDATE_SUPPORT_FORMS_URL(id);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_AWDashboard'),
        },
    })
        .then((res) => {
            dispatch(updateSupportFormsSuccess(res.data && res.data.result, 'Success'));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(updateSupportFormsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const deleteSupportFormInProgress = () => {
    return {
        type: SUPPORT_FORM_DELETE_IN_PROGRESS,
    };
};

const deleteSupportFormSuccess = (value, message) => {
    return {
        type: SUPPORT_FORM_DELETE_SUCCESS,
        value,
        message,
    };
};

const deleteSupportFormError = (message) => {
    return {
        type: SUPPORT_FORM_DELETE_ERROR,
        message,
        variant: 'error',
    };
};

export const deleteSupportForm = (id, data, cb) => (dispatch) => {
    dispatch(deleteSupportFormInProgress());

    const url = DELETE_SUPPORT_FORM_URL(id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_AWDashboard'),
        },
    })
        .then((res) => {
            dispatch(deleteSupportFormSuccess(id, 'Deleted Successfully'));
            cb(res.data && res.data.success);
        })
        .catch((error) => {
            dispatch(deleteSupportFormError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};
