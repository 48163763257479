import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import { setTicketPrice } from '../../../actions/home';

class PriceTextField extends React.Component {
    constructor (props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount () {
        const price = this.props.data && this.props.data.price &&
            this.props.data.price.fiat && this.props.data.price.fiat.amount
            ? (this.props.data.price.fiat.amount && (this.props.data.price.fiat.amount / 100)) : null;
        this.props.onChange(price);
    }

    onChange (value) {
        this.props.onChange(value);
    }

    render () {
        return (
            <TextField
                id="price_text_field"
                name="price"
                placeholder="Price"
                type="number"
                value={this.props.value}
                variant="outlined"
                onChange={this.onChange}/>
        );
    }
}

PriceTextField.propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.home.ticketPrice.value,
    };
};

const actionsToProps = {
    onChange: setTicketPrice,
};

export default connect(stateToProps, actionsToProps)(PriceTextField);
