import * as React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../Home/DeleteEventDialog/index.css';
import CircularProgress from '../../components/CircularProgress';
import { deleteEntry, fetchEntries, hideDeleteEntryDialog } from '../../actions/entries';

const DeleteDialog = (props) => {
    const handleDelete = () => {
        const eventID = props.value && props.value.event && props.value.event._id;
        const gatedAccessID = props.value && props.value.gated_access && props.value.gated_access._id;

        props.deleteEntry(eventID, gatedAccessID, props.value && props.value._id, (result) => {
            if (result) {
                props.fetchEntries(eventID, gatedAccessID);
                props.hideDialog();
            }
        });
    };

    const disable = props.inProgress;

    return (
        <Dialog
            aria-describedby="add-event-dialog-description"
            aria-labelledby="add-event-dialog-title"
            className="dialog add_event_dialog delete_dialog"
            open={props.open}
            onClose={props.hideDialog}>
            <DialogTitle id="alert-dialog-title">
                Delete Entry confirmation
            </DialogTitle>
            {props.inProgress ? <CircularProgress className="full_screen"/> : null}
            <DialogActions className="actions">
                <Button onClick={props.hideDialog}>Cancel</Button>
                <Button autoFocus className="add_button" disabled={disable} onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteDialog.propTypes = {
    deleteEntry: PropTypes.func.isRequired,
    fetchEntries: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.entries.deleteDialog.open,
        value: state.entries.deleteDialog.value,
        inProgress: state.entries.addEntry.inProgress,
    };
};

const actionToProps = {
    deleteEntry,
    fetchEntries,
    hideDialog: hideDeleteEntryDialog,
};

export default connect(stateToProps, actionToProps)(DeleteDialog);
