import {
    USER_LOGIN_EMAIL_SET,
    USER_LOGIN_ERROR,
    USER_LOGIN_IN_PROGRESS,
    USER_LOGIN_PASSWORD_SET,
    USER_LOGIN_SUCCESS,
} from '../constants/login';
import { USER_LOGIN_URL } from '../constants/url';
import Axios from 'axios';

export const setUserLoginEmail = (value, valid) => {
    return {
        type: USER_LOGIN_EMAIL_SET,
        value,
        valid,
    };
};

export const setUserLoginPassword = (value) => {
    return {
        type: USER_LOGIN_PASSWORD_SET,
        value,
    };
};

export const userLoginSuccess = (result, message) => {
    return {
        type: USER_LOGIN_SUCCESS,
        result,
        message,
    };
};

export const userLoginError = (message) => {
    return {
        type: USER_LOGIN_ERROR,
        message,
    };
};

const userLoginInProgress = () => {
    return {
        type: USER_LOGIN_IN_PROGRESS,
    };
};

export const loginUser = (email, password, cb) => (dispatch) => {
    dispatch(userLoginInProgress());

    Axios.post(USER_LOGIN_URL, {
        emailAddress: email.value,
        profilePassword: password,
    }, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(userLoginSuccess(res.data.result, 'Success!'));
            localStorage.setItem('authorizationToken_AWDashboard', res.data.result.access_token);
            localStorage.setItem('refreshToken_AWDashboard', res.data.result.refresh_token);
            cb(null);
        }
    }).catch((error) => {
        dispatch(userLoginError(error.response ? error.response.data.message : error.message,
        ));
    });
};
