export const TICKETS_INFO_FETCH_IN_PROGRESS = 'TICKETS_INFO_FETCH_IN_PROGRESS';
export const TICKETS_INFO_FETCH_SUCCESS = 'TICKETS_INFO_FETCH_SUCCESS';
export const TICKETS_INFO_FETCH_ERROR = 'TICKETS_INFO_FETCH_ERROR';

export const TICKET_TYPE_FETCH_IN_PROGRESS = 'TICKET_TYPE_FETCH_IN_PROGRESS';
export const TICKET_TYPE_FETCH_SUCCESS = 'TICKET_TYPE_FETCH_SUCCESS';
export const TICKET_TYPE_FETCH_ERROR = 'TICKET_TYPE_FETCH_ERROR';

export const UPDATE_TICKET_TYPE_IN_PROGRESS = 'UPDATE_TICKET_TYPE_IN_PROGRESS';
export const UPDATE_TICKET_TYPE_SUCCESS = 'UPDATE_TICKET_TYPE_SUCCESS';
export const UPDATE_TICKET_TYPE_ERROR = 'UPDATE_TICKET_TYPE_ERROR';

export const TICKET_PRICE_SET = 'TICKET_PRICE_SET';
export const UPDATE_TICKET_TYPE_DIALOG_SHOW = 'UPDATE_TICKET_TYPE_DIALOG_SHOW';
export const UPDATE_TICKET_TYPE_DIALOG_HIDE = 'UPDATE_TICKET_TYPE_DIALOG_HIDE';

export const ORDERS_FETCH_IN_PROGRESS = 'ORDERS_FETCH_IN_PROGRESS';
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS';
export const ORDERS_FETCH_ERROR = 'ORDERS_FETCH_ERROR';

export const SUPPORT_FORMS_FETCH_IN_PROGRESS = 'SUPPORT_FORMS_FETCH_IN_PROGRESS';
export const SUPPORT_FORMS_FETCH_SUCCESS = 'SUPPORT_FORMS_FETCH_SUCCESS';
export const SUPPORT_FORMS_FETCH_ERROR = 'SUPPORT_FORMS_FETCH_ERROR';

export const SUPPORT_FORMS_UPDATE_IN_PROGRESS = 'SUPPORT_FORMS_UPDATE_IN_PROGRESS';
export const SUPPORT_FORMS_UPDATE_SUCCESS = 'SUPPORT_FORMS_UPDATE_SUCCESS';
export const SUPPORT_FORMS_UPDATE_ERROR = 'SUPPORT_FORMS_UPDATE_ERROR';

export const SUPPORT_FORM_DELETE_IN_PROGRESS = 'SUPPORT_FORM_DELETE_IN_PROGRESS';
export const SUPPORT_FORM_DELETE_SUCCESS = 'SUPPORT_FORM_DELETE_SUCCESS';
export const SUPPORT_FORM_DELETE_ERROR = 'SUPPORT_FORM_DELETE_ERROR';

export const DELETE_FORM_DIALOG_SHOW = 'DELETE_FORM_DIALOG_SHOW';
export const DELETE_FORM_DIALOG_HIDE = 'DELETE_FORM_DIALOG_HIDE';

export const STATS_FETCH_IN_PROGRESS = 'STATS_FETCH_IN_PROGRESS';
export const STATS_FETCH_SUCCESS = 'STATS_FETCH_SUCCESS';
export const STATS_FETCH_ERROR = 'STATS_FETCH_ERROR';
