import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import CircularProgress from '../../../components/CircularProgress';
import { fetchTicketType, hideUpdateTicketTypeDialog, updateTicketType } from '../../../actions/home';
import PriceTextField from './PriceTextField';

const UpdateTicketTypeDialog = (props) => {
    const handleDelete = () => {
        const data = {};

        if (props.price) {
            data.price = props.price;
        }
        props.updateTicketType(props.value && props.value._id, data, (result) => {
            if (result) {
                props.fetchTicketType();
                props.hideDialog();
            }
        });
    };

    const disable = props.inProgress;

    return (
        <Dialog
            aria-describedby="add-event-dialog-description"
            aria-labelledby="add-event-dialog-title"
            className="dialog add_event_dialog delete_dialog ticket_type_dialog"
            open={props.open}
            onClose={props.hideDialog}>
            <DialogTitle id="alert-dialog-title">
                Update Ticket Type Details
            </DialogTitle>
            <DialogContent>
                <div className="price_field">
                    <p>Price</p>
                    <PriceTextField data={props.value} />
                </div>
            </DialogContent>
            {props.inProgress ? <CircularProgress className="full_screen"/> : null}
            <DialogActions className="actions">
                <Button onClick={props.hideDialog}>Cancel</Button>
                <Button autoFocus className="add_button" disabled={disable} onClick={handleDelete}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

UpdateTicketTypeDialog.propTypes = {
    fetchTicketType: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    updateTicketType: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.home.updateTicketTypeDialog.open,
        value: state.home.updateTicketTypeDialog.value,
        inProgress: state.home.updateTicketType.inProgress,
        price: state.home.ticketPrice.value,
    };
};

const actionToProps = {
    fetchTicketType,
    hideDialog: hideUpdateTicketTypeDialog,
    updateTicketType,
};

export default connect(stateToProps, actionToProps)(UpdateTicketTypeDialog);
