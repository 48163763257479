import React from 'react';
import * as PropTypes from 'prop-types';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { IconButton } from '@mui/material';
import { connect } from 'react-redux';
import withRouter from '../../components/WithRouter';

const BreadCrumb = (props) => {
    const goto = (value) => {
        if (value === 'event') {
            const eventID = props.router && props.router.params && props.router.params.eventID;
            props.router.navigate('/event/' + eventID);
        } else if (value === 'home') {
            props.router.navigate('/home');
        }
    };

    const gatedAccess = props.list && props.list.length && props.list[0];

    return (
        <div className="breadcrumb">
            <div
                className="home_icon"
                onClick={() => goto('home')}>
                <IconButton>
                    <HomeIcon/>
                </IconButton>
            </div>
            <span className="slash">/</span>
            <div className="breadcrumb_collection" onClick={() => goto('event')}>
                Event - {gatedAccess && gatedAccess.event && gatedAccess.event.name
                    ? gatedAccess.event.name
                    : props.router && props.router.params && props.router.params.eventID}
            </div>
            <span className="slash">/</span>
            <div>
                GatedAccess - {gatedAccess && gatedAccess.gated_access && gatedAccess.gated_access.name
                    ? gatedAccess.gated_access.name
                    : props.router && props.router.params && props.router.params.gatedAccessID}
            </div>
        </div>
    );
};

BreadCrumb.propTypes = {
    list: PropTypes.array.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
        params: PropTypes.shape({
            eventID: PropTypes.string,
            gatedAccessID: PropTypes.string,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        list: state.entries.entries.list,
    };
};

export default withRouter(connect(stateToProps)(BreadCrumb));
