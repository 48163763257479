import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../Home/AddEventDialog/index.css';
import './index.css';
import TextField from '../../../components/TextField';
import CircularProgress from '../../../components/CircularProgress';
import { showMessage } from '../../../actions/snackbar';
import { addEntry, fetchEntries, hideEntryDialog, setEntryDeny, setEntryTicketId } from '../../../actions/entries';
import withRouter from '../../../components/WithRouter';

const AddEntryDialog = (props) => {
    const eventID = props.router && props.router.params && props.router.params.eventID;
    const gatedAccessID = props.router && props.router.params && props.router.params.gatedAccessID;

    const handleAdd = () => {
        const data = {
            ticketId: props.ticketID,
        };

        if (props.deny) {
            data.deny = props.deny;
        }

        props.addEntry(eventID, gatedAccessID, data, (result) => {
            if (result) {
                props.fetchEntries(eventID, gatedAccessID);
            }
        });
    };

    const handleChange = (event) => {
        props.setEntryDeny(event.target.checked);
    };

    const disable = props.ticketID === '' || props.inProgress;
    const label = { inputProps: { 'aria-label': 'deny' } };

    return (
        <Dialog
            aria-describedby="entry-dialog-description"
            aria-labelledby="entry-dialog-title"
            className="dialog add_event_dialog entry_dialog"
            open={props.open}
            onClose={props.hideDialog}>
            <DialogTitle id="alert-dialog-title">
                Add Entry
            </DialogTitle>
            <DialogContent>
                {props.inProgress ? <CircularProgress className="full_screen"/> : null}
                <div className="row">
                    <h2>Ticket ID</h2>
                    <TextField
                        id="entry_ticket_id"
                        name="entryTicketId"
                        placeholder="Enter Ticket ID"
                        value={props.ticketID}
                        variant="filled"
                        onChange={props.setEntryTicketId}/>
                </div>
                <div className="row">
                    <h2>Deny</h2>
                    <Switch className="switch" color="primary" {...label} onChange={handleChange}/>
                </div>
            </DialogContent>
            <DialogActions className="actions">
                <Button onClick={props.hideDialog}>Cancel</Button>
                <Button autoFocus className="add_button" disabled={disable} onClick={handleAdd}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddEntryDialog.propTypes = {
    addEntry: PropTypes.func.isRequired,
    deny: PropTypes.bool.isRequired,
    fetchEntries: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setEntryDeny: PropTypes.func.isRequired,
    setEntryTicketId: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    ticketID: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    router: PropTypes.shape({
        params: PropTypes.shape({
            eventID: PropTypes.string,
            gatedAccessID: PropTypes.string,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.entries.addEntry.dialog,
        value: state.entries.addEntry.value,
        inProgress: state.entries.addEntry.inProgress,
        ticketID: state.entries.addEntry.ticketID,
        deny: state.entries.addEntry.deny,
    };
};

const actionToProps = {
    addEntry,
    fetchEntries,
    hideDialog: hideEntryDialog,
    setEntryDeny,
    setEntryTicketId,
    showMessage,
};

export default withRouter(connect(stateToProps, actionToProps)(AddEntryDialog));
