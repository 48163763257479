import React, { Component } from 'react';
import './index.css';
import '../Home/index.css';
import '../GatedAccess/index.css';
import BreadCrumb from './BreadCrumb';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchEntries } from '../../actions/entries';
import EntriesTable from './EntriesTable';
import AddEntryDialog from './AddEntryDialog';
import DeleteDialog from './DeleteDialog';
import variables from '../../utils/variables';
import withRouter from '../../components/WithRouter';

class Entries extends Component {
    componentDidMount () {
        const eventID = this.props.router && this.props.router.params && this.props.router.params.eventID;
        const gatedAccessID = this.props.router && this.props.router.params && this.props.router.params.gatedAccessID;

        if (!this.props.entriesInProgress && eventID && gatedAccessID) {
            this.props.fetchEntries(eventID, gatedAccessID);
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            const eventID = this.props.router && this.props.router.params && this.props.router.params.eventID;
            const gatedAccessID = this.props.router && this.props.router.params && this.props.router.params.gatedAccessID;

            this.props.fetchEntries(eventID, gatedAccessID);
        }
    }

    render () {
        return (
            <div className="home gated_access">
                <div className="table_div">
                    <h2> {variables[this.props.lang].title} </h2>
                    <BreadCrumb/>
                    <div className="table_section">
                        <EntriesTable/>
                    </div>
                </div>
                <AddEntryDialog/>
                <DeleteDialog/>
            </div>
        );
    }
}

Entries.propTypes = {
    entries: PropTypes.array.isRequired,
    entriesInProgress: PropTypes.bool.isRequired,
    fetchEntries: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    auth: PropTypes.string,
    router: PropTypes.shape({
        params: PropTypes.shape({
            eventID: PropTypes.string,
            gatedAccessID: PropTypes.string,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        entries: state.entries.entries.list,
        entriesInProgress: state.entries.entries.inProgress,
    };
};

const actionToProps = {
    fetchEntries,
};

export default withRouter(connect(stateToProps, actionToProps)(Entries));
