import React from 'react';
import './index.css';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import withRouter from '../../components/WithRouter';

const NavBar = (props) => {
    const onClick = () => {
        localStorage.removeItem('authorizationToken_AWDashboard');
        localStorage.removeItem('refreshToken_AWDashboard');
        props.router.navigate('/login');
    };

    return (
        <div className="navbar">
            <Button
                className="disconnect_button"
                onClick={() => onClick()}>
                Logout
            </Button>
        </div>
    );
};

NavBar.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

export default withRouter(NavBar);
