import React from 'react';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import { fetchTicketType, showUpdateTicketTypeDialog } from '../../actions/home';
import moment from 'moment';
import { Button } from '@material-ui/core';

const TicketTypeTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketType(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketType(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketType(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketType(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: false,
        pagination: true,
        // count: props.list && props.list.length,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
        title: true,
    };

    const columns = [{
        name: 'name',
        label: 'Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="user_address">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'available_tickets',
        label: 'Available Tickets',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="user_address">{value}
                    </div>
                );
            },
        },
    }, {
        name: 'total_tickets',
        label: 'Total Tickets',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'start_time',
        label: 'Start Time',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value && moment(value).format('MMM Do, h:mm A')}
                    </div>
                );
            },
        },
    }, {
        name: 'end_time',
        label: 'End Time',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value && moment(value).format('MMM Do, h:mm A')}
                    </div>
                );
            },
        },
    }, {
        name: 'price.fiat.amount',
        label: 'Price',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="status_button">
                        <Button
                            className="update_button"
                            onClick={() => props.showUpdateTicketTypeDialog(value)}>
                            Update
                        </Button>
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [
                item.name,
                item.available_tickets,
                item.total_tickets,
                item.start_time,
                item.end_time,
                item.price && item.price.fiat && item.price.fiat.amount
                    ? item.price.fiat.amount / 100 : null,
                item,
            ],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

TicketTypeTable.propTypes = {
    fetchTicketType: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    showUpdateTicketTypeDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.ticketTypes.list,
        inProgress: state.home.ticketTypes.inProgress,
        skip: state.home.ticketTypes.skip,
        limit: state.home.ticketTypes.limit,
        sortBy: state.home.ticketTypes.sortBy,
        order: state.home.ticketTypes.order,
        searchKey: state.home.ticketTypes.searchKey,
    };
};

const actionsToProps = {
    fetchTicketType,
    showUpdateTicketTypeDialog,
};

export default connect(stateToProps, actionsToProps)(TicketTypeTable);
