import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './containers/Home';
import LoginPage from './containers/Login';
import NavBar from './containers/NavBar';
import GatedAccess from './containers/GatedAccess';
import Entries from './containers/Entries';

const routes = [{
    path: '/home',
    component: Home,
}, {
    path: '/event/:eventID',
    component: GatedAccess,
}, {
    path: '/event/:eventID/gatedAccess/:gatedAccessID',
    component: Entries,
}, {
    path: '/login',
    component: LoginPage,
}];

const Router = () => {
    const token = localStorage.getItem('authorizationToken_AWDashboard');

    return (
        <div className="main_content">
            {token && <NavBar/>}
            <div className="content_div scroll_bar">
                <Routes>
                    {routes.map((route) =>
                        <Route
                            key={route.path}
                            exact
                            element={<route.component/>}
                            path={route.path}/>,
                    )}
                    <Route
                        exact
                        element={<LoginPage/>}
                        path="*"/>
                </Routes>
            </div>
        </div>
    );
};

export default Router;
