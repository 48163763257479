import React from 'react';
import * as PropTypes from 'prop-types';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { IconButton } from '@mui/material';
import { connect } from 'react-redux';

const BreadCrumb = (props) => {
    const goto = () => {
        props.router.navigate('/home');
    };

    const gatedAccess = props.list && props.list.length && props.list[0];

    return (
        <div className="breadcrumb">
            <div
                className="home_icon"
                onClick={() => goto('home')}>
                <IconButton>
                    <HomeIcon/>
                </IconButton>
            </div>
            <span className="slash">/</span>
            <div>
                Event - {gatedAccess && gatedAccess.event && gatedAccess.event.name
                    ? gatedAccess.event.name
                    : props.router && props.router.params && props.router.params.eventID}
            </div>
        </div>
    );
};

BreadCrumb.propTypes = {
    list: PropTypes.array.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
        params: PropTypes.shape({
            eventID: PropTypes.string,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        list: state.gatedAccess.gatedAccesses.list,
    };
};

export default connect(stateToProps)(BreadCrumb);
