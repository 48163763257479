import React from 'react';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import moment from 'moment';
import { Button } from '@material-ui/core';
import Icon from '../../components/Icon';
import '../Home/index.css';
import { showAddGatedAccessDialog, showDeleteGatedAccessDialog } from '../../actions/gatedAccess';
import withRouter from '../../components/WithRouter';

const GatedAccessTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onCellClick: (colData, cellMeta) => {
            const data = props.list && props.list[cellMeta && cellMeta.rowIndex];

            if (cellMeta.colIndex !== 8) {
                props.router.navigate(`/event/${data && data.event && data.event._id}/gatedAccess/${data && data._id}`);
            }
        },
        customToolbar: () => {
            return (
                <span>
                    <Button className="add_icon" href="" onClick={() => props.showDialog({})}>
                        <Icon className="add" icon="add"/>
                    </Button>
                </span>
            );
        },
        responsive: 'standard',
        serverSide: false,
        pagination: true,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
        title: true,
    };

    const columns = [{
        name: '_id',
        label: 'Gated access ID',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div>{value}</div>
                );
            },
        },
    }, {
        name: 'image_URL',
        label: 'Event',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'name',
        label: 'Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'allowed_entries',
        label: 'Allowed Entries',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'denied_entries',
        label: 'Denied Entries',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'total_entries',
        label: 'Total Entries',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'start_time',
        label: 'Start Time',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value && moment(value).format('MMM Do, h:mm A')}
                    </div>
                );
            },
        },
    }, {
        name: 'end_time',
        label: 'End Time',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value && moment(value).format('MMM Do, h:mm A')}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="status_button">
                        <Button
                            className="delete_button"
                            onClick={() => props.showDialog(value)}>
                            Update
                        </Button>
                        <Button
                            className="delete_button"
                            onClick={() => props.showDeleteGatedAccessDialog(value)}>
                            Delete
                            {props.addGatedAccessInProgress ? <CircularProgress/> : null}
                        </Button>
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item._id,
                item.event && item.event.name,
                item.name,
                item.allowed_entries,
                item.denied_entries,
                item.total_entries,
                item.start_time,
                item.end_time,
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

GatedAccessTable.propTypes = {
    addGatedAccessInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    showDeleteGatedAccessDialog: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.gatedAccess.gatedAccesses.list,
        inProgress: state.gatedAccess.gatedAccesses.inProgress,
        addGatedAccessInProgress: state.gatedAccess.addGatedAccess.inProgress,
    };
};

const actionsToProps = {
    showDeleteGatedAccessDialog,
    showDialog: showAddGatedAccessDialog,
};

export default withRouter(connect(stateToProps, actionsToProps)(GatedAccessTable));
