import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { USER_LOGIN_ERROR, USER_LOGIN_SUCCESS } from '../constants/login';
import {
    ORDERS_FETCH_ERROR,
    SUPPORT_FORM_DELETE_ERROR,
    SUPPORT_FORM_DELETE_SUCCESS,
    SUPPORT_FORMS_UPDATE_ERROR,
    SUPPORT_FORMS_UPDATE_SUCCESS,
    TICKETS_INFO_FETCH_ERROR, UPDATE_TICKET_TYPE_ERROR, UPDATE_TICKET_TYPE_SUCCESS,
} from '../constants/home';
import {
    ADD_EVENT_ERROR,
    ADD_EVENT_SUCCESS,
    DELETE_EVENT_ERROR,
    DELETE_EVENT_SUCCESS,
    UPDATE_EVENT_ERROR,
    UPDATE_EVENT_SUCCESS,
} from '../constants/events';
import {
    ADD_GATED_ACCESS_ERROR,
    ADD_GATED_ACCESS_SUCCESS,
    DELETE_GATED_ACCESS_ERROR,
    DELETE_GATED_ACCESS_SUCCESS,
    GATED_ACCESSES_FETCH_ERROR,
    UPDATE_GATED_ACCESS_ERROR,
    UPDATE_GATED_ACCESS_SUCCESS,
} from '../constants/gatedAccess';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case SUPPORT_FORM_DELETE_ERROR:
    case SUPPORT_FORM_DELETE_SUCCESS:
    case SUPPORT_FORMS_UPDATE_ERROR:
    case SUPPORT_FORMS_UPDATE_SUCCESS:
    case MESSAGE_SHOW:
    case USER_LOGIN_SUCCESS:
    case USER_LOGIN_ERROR:
    case TICKETS_INFO_FETCH_ERROR:
    case UPDATE_TICKET_TYPE_SUCCESS:
    case UPDATE_TICKET_TYPE_ERROR:
    case ORDERS_FETCH_ERROR:
    case ADD_EVENT_SUCCESS:
    case ADD_EVENT_ERROR:
    case UPDATE_EVENT_SUCCESS:
    case UPDATE_EVENT_ERROR:
    case DELETE_EVENT_SUCCESS:
    case DELETE_EVENT_ERROR:
    case ADD_GATED_ACCESS_SUCCESS:
    case ADD_GATED_ACCESS_ERROR:
    case UPDATE_GATED_ACCESS_SUCCESS:
    case UPDATE_GATED_ACCESS_ERROR:
    case DELETE_GATED_ACCESS_SUCCESS:
    case DELETE_GATED_ACCESS_ERROR:
    case GATED_ACCESSES_FETCH_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
